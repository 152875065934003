/*
 * This Service which will allow you to send information
 * from the Fromik form to the CRM.
*/

import { getBaseUrl } from './functions';

class PostService {
  constructor() {
    // If express URL is explicitly declared, use it. Otherwise, calculate it.
    this.baseUrl = getBaseUrl();
  }

  /*
   * Post the Data to CRM.
  */
  async postData(endpoint, submittedValues, form = false) {

    // Get all the Cookies.
    const cookies = await this.getCookieData();

    const houseType = submittedValues.houseType;

    // Hardcode the UTM Content for Self Scheduler.
    if (submittedValues.utm_content === 'ss-delay' && houseType !== 'rent') {
      cookies.utm_content = 'ss-delay';
    }

    // deleting house type property from submitted values.
    delete submittedValues.houseType;

    // Get all the Submitted Values from the user.
    const data = Object.assign({}, submittedValues, cookies);

    const reCaptchaScore = ( process.env.REACT_APP_PANTHEON_ENVIRONMENT === 'live' ? process.env.REACT_APP_RECAPTCHA_SCORE : process.env.REACT_APP_NON_PROD_RECAPTCHA_SCORE );

    // Update the web_lead_source value.
    data['web_lead_source'] = data.utm_source || '41';

    if (endpoint !== 'create-lead') {
      data['Web Lead Source'] = data.utm_source || '41';
    }

    // Passing the Data to UTMSource field.
    data.utmSource = data.utm_source;

    if (submittedValues.source === '' || submittedValues.source === '41') {
      data['source'] = data.utm_source || '41';
    }

    // Handing additional conditions.
    if (data.utm_source === '41BA' || data.utm_medium === 'smart') {
      if (data.recaptcha_score < reCaptchaScore) {
        data['web_lead_source'] = data.source;
        if (endpoint !== 'create-lead') {
          data['Web Lead Source'] = data.source;
        }
        data.source = data.utm_source;
      }
    }

    // Merge the Submitted values and cookies array.
    const information = (form) ? { form: data } : data;

    try {
      const response = await fetch(`${this.baseUrl}/api/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(information),
      });
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error("Error posting data:", error);
      throw error;
    }
  }

   /*
   * Post the Data to CRM.
  */
  async sendMail(endpoint, submittedValues) {

      try {
        const response = await fetch(`${this.baseUrl}/api/${endpoint}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(submittedValues),
        });

        let responseData = {}
        if (response.status === 200 && response.ok === true) {
          responseData = {
            success: true,
            message: "Mail is send"
          }
        }
        else {
         responseData = await response.json();
        }
        return responseData;
      } catch (error) {
        console.error("Error posting data:", error);
        throw error;
      }
  }

  /**
   * Upload file to cloudinary server.
   * @param {*} endpoint
   * @param {*} submittedValues
   * @returns
   */
  async uploadFile (endpoint, submittedValues) {

    try {
      const response = await fetch(`${this.baseUrl}/api/${endpoint}`, {
        method: "POST",
        body: submittedValues
      });
      const resData = await response.json();

      const responseData = {
        success: true,
        secure_url: resData.secure_url,
        url: resData.url,
        bytes: resData.bytes,
        created: resData.created,
      }
      return responseData;
    } catch (error) {
      console.error("Error posting data:", error);
      throw error;
    }
}

  /*
   * Get all the Cookie Information.
  */
  async getCookieData() {

    const results = [];

    // List all the UTM attributes.
    const utmAttributes = await this.getUTMAttributes();

    const cookies = [
      '_mkto_trk',
      '_fbc',
      '_fbp',
      '_ttp',
      'ttclid',
      'clientIPAddress'
    ];

    for (const cookieName of cookies) {
      try {
        results[cookieName] = await this.readCookie(cookieName);
      } catch (error) {
        console.error("Error fetching and posting data:", error);
        throw error;
      }
    }

    const cookieAttributes = Object.assign({}, results, utmAttributes);

    return cookieAttributes;
  }

  /*
   * Read the Cookie Values.
  */
  async readCookie(cookieName) {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === cookieName) {
        return decodeURIComponent(value);
      }
    }
    return null;
  }

  /*
   * Get the attributes from lfac cookie.
  */
  async getLfacCookie() {
    const lfacCookie = [];
    // Get the Source + GUID value from `lfac` cookie.
    const trackingCookie = await this.readCookie('lfac');
    if (trackingCookie !== null) {
      const response = JSON.parse(trackingCookie);

      Object.assign(lfacCookie, response);
    }

    return lfacCookie;
}
  /*
   * Get the Organic Cookie.
  */
  async getOrganicCookie() {
    let response = {};
    const organicCookie = await this.readCookie('lfog');
    if (organicCookie !== null) {
      response = JSON.parse(organicCookie);
    }

    return response;
  }

  /*
   * Get UTM attributes.
  */
  async getUTMAttributes() {

    // List all the lfac Cookie attributes.
    const lfacAttributes = await this.getLfacCookie();

    // List all the UTM attributes.
    const organicAttributes = await this.getOrganicCookie();

    let queryParameters = {};
    // Get all the Search Parameters.
    const searchParams = new URLSearchParams(window.location.search);

    // List of Query string Attributes.
    const queryStringAttributes = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'feeditemid',
      'adposition',
      'loc_physical_ms',
      'campaignid',
      'adgroupid',
      'gclid'
    ];

    for (const queryStringAttribute of queryStringAttributes) {
      if (searchParams.has(queryStringAttribute)) {
        queryParameters[queryStringAttribute] = searchParams.get(queryStringAttribute);
      }
    }

    // UTM Attributes with the combination of lfac cookie.
    const utmAttributes = Object.assign({}, lfacAttributes, queryParameters, organicAttributes);

    return utmAttributes;
  }

  /*
   * Load data from Marketo.
  */
  async loadDataMarketo() {
    const marketoCookie = await this.readCookie('_mkto_trk');
    if (marketoCookie) {
      const information = { 'marketo_id': marketoCookie };
      try {
        const response = await fetch(`${this.baseUrl}/api/get-lead`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(information),
        });
        const responseData = await response.json();
        if (responseData.success) {
          return responseData.result;
        }
      } catch (error) {
        console.error("Error posting data:", error);
        throw error;
      }
    }

    return undefined;
  }

  /*
   * Check if Leaf team serves in the entered Zipcode.
  */
  async checkAvailableService(zipcode, product, zipsource) {
    try {
      const apiParameters = '?product=' + product + '&zipcode=' + zipcode.toUpperCase() + '&path=' + window.location.href + '&zipsource=' + zipsource;
      const response = await fetch(`${this.baseUrl}/api/read-zipcode${apiParameters}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error reading the file:', error);
    }
    return false;
  }

  /*
   * Retain the Active Prospect Certificate.
  */
  async retainCertificate(endpoint, submittedValues) {

    const information = {
      'certificateLink': submittedValues.certificateLink,
      'user_data': {
        'retain': {},
        'match_lead': {
          'email': submittedValues.userEmail,
          'phone': submittedValues.userPhone,
        }
      }
    };

    try {
      const response = await fetch(`${this.baseUrl}/api/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(information),
      });
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error("Error posting data:", error);
      throw error;
    }
  }
}

const postservice = new PostService();
export default postservice;
