import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import * as yup from "yup";
import {
  Container,
  Paper
} from "@mui/material";
import Divider from "@mui/material/Divider";
import PostService from "utility/postService";
import DataLayerService from "utility/dataLayerService";
import Validation from "utility/validation";
import { getactiveTestScriptAP } from '../../../../utility/functions';

// Import SVG Icons.
import LeafBox from "components/atoms/LeafBox";
import { LHOnline, LHEvent, LHPrint, LHPostcard } from "components/atoms/Icons";


import { FormContextLHSSMultiStepEstimate } from "./LHSSMultiStepEstimate";
import styled from "@emotion/styled";
import PhoneNumberInput from "components/atoms//PhoneNumberInput";
import SMSMarketingCampaignComponent from "components/atoms/SMSMarketingCampaign";
import SMSMarketingTermAndCondition from "components/atoms/SMSMarketingTermAndCondition";
import { LeafButton } from "components/atoms/LeafButtons";
import { useCountry, useReCaptchaScore } from "hooks";
import ProgressBar from "components/atoms/ProgressBar";
import ReCAPTCHA from "react-google-recaptcha"

const FinalStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const country = useCountry();
  // const product = useProduct();
  const activeProspectTestScript = getactiveTestScriptAP();
  const { setActiveLHSSStepIndex, formData, setFormData, marketoData } = useContext(FormContextLHSSMultiStepEstimate);
  // const userJourneyTracking = props.dataSet?.tracking || '';
  // const componentName = props.component || 'LeafHomeZipChecker';
  // const formName = useComponentName(componentName);
  // const subformName = useComponentName(props.modalComponent);

  let phoneNumber = '';
  let errorMsg = "";
  let captchaScore = null;

  // Disabling recaptcha.
  const reCaptchaShow = process.env.PANTHEON_ENVIRONMENT === 'live' ? (process.env.REACT_APP_RECAPTCHA_SHOW_PROD === 'enable') : (process.env.REACT_APP_RECAPTCHA_SHOW_NON_PROD === 'enable');

  const ValidationSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Phone Number is not valid.")
      .required("Phone Number is required."),
  });

  const buttonSubmit = (e) => {

    // Validation Error.
    const phoneValidation = Validation.PhoneDataLayerValidation(phoneNumber);

    if (Object.keys(phoneValidation).length !== 0) {
      DataLayerService.formFailEvent(form, location, phoneValidation);
    }
  }

  const handleImageClick = (value, setFieldValue) => {
    setFieldValue("adLevel", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleOnBlur = (event) => {
    phoneNumber = event.target.value;
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const recaptchaRef = useRef();
  const reCaptchaScoreConstant = useReCaptchaScore();

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: 552,
    width: "100%",
    height: "auto",
  }));

  let phone = '';
  if (marketoData !== undefined && marketoData !== null) {
    phone = marketoData.phone;
  }

  return (
    <Formik
      initialValues={{
        phoneNumber: phone ? phone : '',
        adLevel: "",
        trustedCertURL: document.getElementsByName("xxTrustedFormCertUrl")[0]
        ? document.getElementsByName("xxTrustedFormCertUrl")[0].value
        : "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={async (values, actions) => {
        // Validate if the user is not a Robot.
        let captchaValue = '';
        const RecaptchaVerification = await Validation.RecaptchaVerification();
        captchaScore = RecaptchaVerification.score;

        if (captchaScore < reCaptchaScoreConstant) {
          captchaValue = recaptchaRef.current.getValue();
        }
        else {
          captchaValue = RecaptchaVerification.token;
        }

        if (captchaValue || !reCaptchaShow) {
          const data = { ...formData, ...values };
          setFormData(data);

          let roleType = '';
          switch (formData.roleType) {
            case 'homeOwner':
              roleType = 'Home Owner';
              break;
            case 'familyMember':
              roleType = 'Family Member';
              break;
            case 'careTaker':
              roleType = 'Care Taker';
              break;
            default:
              roleType = '';
              break;
          }

          const comments = "Project Status:" + (formData.projectStatus === 2 ? 'Planning And Budgeting' : 'Ready To Hire') + "\n" +
            "Role Type:" + roleType + "\n" +
            "Financing: " + formData.financing + "\n" +
            "Senior:" + (formData.groupLevel === "senior" ? "Yes" : "No") + "Veteran:No\n" +
            "txtSMSMarketingConsent:" + (values.txtSMSMarketingConsent ? true : false) +
            "\nTrusted Form URL:" + values.trustedCertURL +
            "\nSurvey:" + values.adLevel +
            "\nRecaptcha Score:" + captchaScore;

          let submittedValue = {
            'Offer': '0.10',
            'web_lead_source': "",
            'active_campaign': 'default',
            'comments': comments,
            'country': country,
            'emailAddress': formData.emailAddress,
            'firstName': formData.firstName,
            'fullName': formData.firstName + " " + formData.lastName,
            'lastName': formData.lastName,
            'phoneNumber': values.phoneNumber,
            'zipCode': formData.zipCode,
            'landing_page': window.location.pathname,
            'product': formData.product,
            'recaptcha_token': captchaValue,
            'recaptcha_score': captchaScore,
            'source': (formData.utm_campaign.substr(-3, 3) !== "-ND") ? values.adLevel : '',
            'QualifiedLead': false,
            'FirstName': formData.firstName,
            'LastName': formData.lastName,
            'Email': formData.emailAddress,
            'Phone': values.phoneNumber,
            'Zip': formData.zipCode,
            'WebEventDetail': '',
            'LeadSourceId': 1,
            'LeadSource': '41',
            'Notes': '',
            'surveyResults': values.adLevel,
          }

          try {
            // Generate Marketo Lead.
            PostService.postData('create-lead', submittedValue);
          } catch (error) {
            console.log(error);
            console.log("Error in sending data");
          }

          if (activeProspectTestScript === 'nosandbox') {

            const certificateInformation = {
              'certificateLink': values.trustedCertURL,
              'userEmail': formData.emailAddress,
              'userPhone': values.phoneNumber,
            }

            try {
              const response = await PostService.retainCertificate('retain-cert', certificateInformation);
              console.log(response);
            } catch (error) {
              console.log(error);
              console.log("Error in retaining the Certificate.");
            }
          }

          try {
            const response = await PostService.postData('lhss-estimate', submittedValue, true);
            if (response.success) {
              setActiveLHSSStepIndex('ThankYou');

              // Passing lf.form.success event to DataLayer.
              DataLayerService.formSuccessEvent(form, 'estimate', location);

              // Passing lf.form.start event to DataLayer.
              DataLayerService.formStartEvent(form, location, data);

              localStorage.removeItem("zipCode");
            }
            else {
              errorMsg = "Sorry, there was an error during submission. Please try again.";
              console.log(response.msg);
              actions.setSubmitting(false);
            }
          } catch (error) {
            console.log(error);
            console.log("Error in sending data");
          }
        }
      }}
    >
      {({ handleSubmit, setFieldValue, isSubmitting }) => (
        <Container maxWidth={"sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              className="flex flex-col justify-center items-start"
              onSubmit={handleSubmit}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              <h6 className="tracking-wide text-uppercase mb-4 fw-normal text-gray-600">Free Estimate</h6>
              <h3 className="fw-normal mb-3">
                To ensure pricing is 100% accurate, we need to verify a few details.
              </h3>
              <div className="flex flex-col items-start mb-2">
                {errorMsg ? (<div className="border border-danger border-1 p-2 mb-4 error-msg fw-normal">{errorMsg}</div>) : ""}
                <PhoneNumberInput fieldName={'phoneNumber'} phoneNumberText="" />
              </div>
              <SMSMarketingCampaignComponent />

              <Divider className="divider" />

              <LeafBox>
                <label className="small mb-3">How did you hear about us?</label>
                <Field name="adLevel">
                  {() => (
                    <LeafBox>
                      <LHOnline handleClick={() =>
                        handleImageClick("41", setFieldValue)
                      } />

                      <LHEvent handleClick={() =>
                        handleImageClick("99w", setFieldValue)
                      } />

                      <LHPrint handleClick={() =>
                        handleImageClick("88w", setFieldValue)
                      } />

                      <LHPostcard handleClick={() =>
                        handleImageClick("10w", setFieldValue)
                      } />
                    </LeafBox>
                  )}
                </Field>

              </LeafBox>

              <Field type="hidden" name="trustedCertURL" />
              {reCaptchaShow && (
                <div className="recaptcha">
                  {captchaScore !== null && captchaScore < reCaptchaScoreConstant && (
                    <>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="normal"
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
                      />
                      <p className="error-msgs">reCAPTCHA verification failed. Please try again.</p>
                    </>
                  )}
                </div>
              )}
              <LeafBox>
                <LeafButton
                  type="submit"
                  buttonClass="btn-primary"
                  handleClick={() => buttonSubmit()}
                  style={{
                    width: "100%"
                  }}
                  isSubmitting={isSubmitting}
                >
                  {isSubmitting ? ("Submitting") : ("Submit")}
                </LeafButton>
              </LeafBox>
              <ProgressBar customClass="" />
              <SMSMarketingTermAndCondition />
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default FinalStep;
