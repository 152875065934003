import { createContext, useState, useContext, useEffect } from "react";
import PostService from "utility/postService";
import InitialStep from "./InitialStep";
import CustomerGroupStep from "./CustomerGroupStep";
import CustomerDetailsStep from "./CustomerDetailsStep";
import ProjectDetailsStep from "./ProjectDetailsStep";
import FinalStep from "./FinalStep";
import ThankYou from "./thankyou";
import ThankYouSelfScheduler from "./thankyouselfscheduler"
import "../../global-style.scss";
import TrustedFormScript from "components/atoms/TrustedFormScript";
import { FormContext } from "../../../../../App";

export const FormContextLHWSMultiStepEstimate = createContext({
  activeLHWSStepIndex: ''
});

const  LHWSMultiStepEstimate = (props) => {
  const [ activeLHWSStepIndex, setactiveLHWSStepIndex ] = useState('');
  const [formData, setFormData] = useState({ zipCode: props.zipCode });
  const [ rentHome, setRentHome ] = useState(false);
  const selfScheduler = props.dataSet?.selfscheduler ? props.dataSet.selfscheduler : 'disable';
  const formVersion = props.dataSet?.version ? true : false;
  const leahomeVersion = props.dataSet?.vleafhome ? props.dataSet?.vleafhome : true;
  const { v2, setV2, setVLeafhome } = useContext(FormContext);
  useEffect(() => {
    setV2(formVersion);
    setVLeafhome(leahomeVersion);
  });

  let marketoFlag = false;
  let marketoData = {}

  if (marketoFlag === undefined || marketoFlag === null || marketoFlag === false) {
    const loadData = PostService.loadDataMarketo();
    loadData.then((data) => {
      marketoData = data
    });
    marketoFlag = true;
  }

  let stepContent;
  switch (activeLHWSStepIndex) {
    case 'group':
      stepContent = <CustomerGroupStep marketoData={ marketoData } {...props} />;
      break;
    case 'details':
      stepContent = <CustomerDetailsStep marketoData={ marketoData } {...props} />;
      break;
    case 'project':
      stepContent = <ProjectDetailsStep marketoData={ marketoData } {...props} />;
      break;
    case 'final':
      stepContent = <FinalStep marketoData={ marketoData } {...props} />;
      break;
    case 'ThankYou':
      // Option to decide whether we want Self Scheduler or not.
      if (selfScheduler === 'enable' && !rentHome) {
        stepContent = <ThankYouSelfScheduler {...props} />;
      }
      else {
        stepContent = <ThankYou {...props} />;
      }
      break;
    default:
      stepContent = <InitialStep marketoData={ marketoData } {...props} />;
      break;
  }

  return (
    <FormContextLHWSMultiStepEstimate.Provider
      value={{activeLHWSStepIndex, setactiveLHWSStepIndex, formData, setFormData, marketoData, rentHome, setRentHome }}
    >
      <div className={`leafhome-forms ${v2 ? 'version-2' : ''}`}>{stepContent}</div>
      <TrustedFormScript />
    </FormContextLHWSMultiStepEstimate.Provider>
  );
}

export default LHWSMultiStepEstimate;