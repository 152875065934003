import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import * as yup from "yup";
import { Container, FormControl, useTheme, Paper } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import DataLayerService from "utility/dataLayerService";
import Validation, { useZipCodeValidation } from "utility/validation";
import PostService from "utility/postService";
import LeafBox from "components/atoms/LeafBox";

// Import SVG Icons.
import { LHWellWater, LHCityWater, LHUnsure, LHWaterOdor, LHNoOdor, LHWSRenter, LHWSHomeOwner } from "components/atoms/Icons";

import { FormContextLHWSMultiStepEstimate } from "./LHWSMultiStepEstimate";
import styled from "@emotion/styled";
import ZipCodeInput from "components/atoms/ZipcodeInput";
import TermAndCondition from "components/atoms/TermAndCondition";
import { LeafButton } from "components/atoms/LeafButtons";
import { useProduct, useComponentName, useVersion } from "hooks";
import ProgressBar from "components/atoms/ProgressBar";
import ProgressBarWithPercentage from 'components/atoms/ProgressBarWithPercentage';
import { scrollToTop } from '../../../../../utility/functions';

const InitialStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const zipSource = props.dataSet?.zipsource || "localzip";
  let zipCode = '';
  let zipcodeInputValue = '';
  let serviceAreaFlag = false;
  const product = useProduct();
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'LeafHomeZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  let zipCodeValidationStatus = 'invalid';
  const v2 = useVersion();
  const formRef = useRef(null);

  // Zipcode Validation based on the Country Code.
  const zipCodeValidation = useZipCodeValidation();

  const { setactiveLHWSStepIndex, formData, setFormData } = useContext(FormContextLHWSMultiStepEstimate);
  const houseTypeEnable =  props.dataSet?.housetype ? props.dataSet.housetype : 'disable';

  const ValidationSchema = yup.object().shape({
    zipCode: yup
      .string()
      .matches(zipCodeValidation.validationPattern, zipCodeValidation.validationMessage)
      .required(`${zipCodeValidation.zipCodeText} is required.`)
      .test('available-zipcode', zipCodeValidation.restrictedValidationMessage, async function (zipcode) {
        if (!(formData.zipCode) && (zipcodeInputValue !== zipcode) && (zipCodeValidation.validationPattern.test(zipcode))) {
          serviceAreaFlag = await PostService.checkAvailableService(zipcode, product, zipSource);
          zipcodeInputValue = zipcode;
          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if (!serviceAreaFlag) {
            DataLayerService.formFailEvent(form, location, {'zipCode': ['OOA restriction for ' + zipcode + ' failed.']});
          }
          if (userJourneyTracking) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipcodeInputValue, 'completed', zipCodeValidationStatus);
          }
          // Return true if the validation passes, false if it fails
          return serviceAreaFlag;
        }
        else {
          if (formData.zipCode) {
            return true;
          }

          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if ((userJourneyTracking) && formData.zipCode !== undefined) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipcode, 'completed', zipCodeValidationStatus);
          }
          return serviceAreaFlag;
        }
      }),
  });

  const buttonSubmit = (e) => {

    // Validation Error.
    const zipCodevalidationError = Validation.ZipValidationCodeDataLayerValidation(zipCode, zipCodeValidation.validationPattern);

    if ((Object.keys(zipCodevalidationError).length !== 0) && (componentName !== 'LeafHomeZipChecker')) {
      DataLayerService.formFailEvent(form, location, zipCodevalidationError);
    }

    if (v2) {
      scrollToTop(formRef);
    }
  }

  const handleOnBlur = (event) => {
    zipCode = event.target.value;
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleTypeOfWaterClick = (value, setFieldValue) => {
    setFieldValue("typeofwater", value);
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleWaterColorClick = (value, setFieldValue) => {
    setFieldValue("watercolor", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleRenterOwnerClick = (value, setFieldValue) => {
    setFieldValue("houseType", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: isMobile ? "100%" : 520,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));

  return (
    <Formik
      initialValues={{
        zipCode: formData.zipCode
          ? formData.zipCode
          : localStorage.getItem("zipCode") || '',
        typeofwater: "",
        watercolor: "",
        houseType: ""
      }}
      validationSchema={ValidationSchema}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={async (values) => {
        const cookies = await PostService.getUTMAttributes();
        const additionalInformation = {
          guid: cookies.guid,
          utm_campaign: cookies.utm_campaign ? cookies.utm_campaign.toUpperCase() : '',
        };
        const data = { ...formData, ...values, ...additionalInformation };
        setFormData(data);

        // Passing lf.form.assessment event to DataLayer.
        DataLayerService.formAssessmentEvent(data);

        setactiveLHWSStepIndex('group');

        if (userJourneyTracking) {
          let stepNumber = 1;
          if ((componentName === 'LeafHomeZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 2;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 1, 'notcompleted');
        }

        if (v2) {
          scrollToTop(formRef);
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, setFieldValue, values, touched, errors, isSubmitting }) => (
        <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
           <StyledFormContainer>
                <Form
                  className="flex flex-col justify-center items-start step1"
                  onSubmit={handleSubmit}
                  ref={formRef}
                  onBlur={
                    (event) => {handleOnBlur(event)}
                  }
                >
                  <h6 className="tracking-wide text-uppercase mb-4 fw-normal text-gray-600">Free Estimate</h6>
                  <h3 className="fw-normal mb-3">
                    Schedule your FREE water test to find out what’s in your water.
                  </h3>
                  <div className="label-bold">
                    <ZipCodeInput
                      fieldName={'zipCode'}
                      style={{
                        display: formData.zipCode ? "none" : "inline"
                      }}
                      label={formData.zipCode ? false : true}
                    />
                  </div>

                  <FormControl component="fieldset">
                    <label className="small mb-3">What type of water do you have?</label>
                    <div>
                      <Field name="product">
                        {({ field }) => (
                          <LeafBox style={{
                            display: "flex",
                            alignItems: "start"
                          }}>
                              <LHWellWater handleClick={() =>
                                handleTypeOfWaterClick("Well Water", setFieldValue)
                              } />
                              <LHCityWater handleClick={() =>
                                handleTypeOfWaterClick("City Water", setFieldValue)
                              } />
                              <LHUnsure handleClick={() =>
                                handleTypeOfWaterClick("Unsure", setFieldValue)
                              } />
                            </LeafBox>
                        )}
                      </Field>
                    </div>
                  </FormControl>
                  {houseTypeEnable === 'enable' ?
                  <div>
                    <FormControl component="fieldset">
                      <label className="small mb-3">Do you rent or own your house?</label>
                      <div>
                        <Field name="projectStatus">
                          {({ field }) => (
                            <LeafBox style={{
                              marginBottom: "0",
                            }}>
                              <LHWSRenter handleClick={() =>
                                handleRenterOwnerClick ("Rent" , setFieldValue)
                              } />
                              <LHWSHomeOwner handleClick={() =>
                                handleRenterOwnerClick ("Own" , setFieldValue)
                              } />
                            </LeafBox>
                          )}
                        </Field>
                      </div>
                    </FormControl>
                  </div>
                  : ''}
                  <div>
                    <FormControl component="fieldset">
                      <label className="small mb-3">Does your water have a strong taste or odor?</label>
                      <div>
                        <Field name="projectStatus">
                          {({ field }) => (
                            <LeafBox style={{
                              marginBottom: "0",
                            }}>
                              <LHWaterOdor handleClick={() =>
                                handleWaterColorClick ( 1 , setFieldValue)
                              } />
                              <LHNoOdor handleClick={() =>
                                handleWaterColorClick ( 2 , setFieldValue)
                              } />
                            </LeafBox>
                          )}
                        </Field>
                      </div>
                    </FormControl>
                  </div>
                  <Divider className="divider" />
                  <LeafButton
                    buttonClass='rounded-3 btn-primary w-100 fw-medium'
                    type="submit"
                    handleClick={buttonSubmit}
                    isSubmitting={isSubmitting}
                  >
                    Next
                  </LeafButton>
                  { v2 ?
                    <ProgressBarWithPercentage />
                    :
                    <ProgressBar customClass="" />
                  }
                  <TermAndCondition />
                </Form>
            </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default InitialStep;
